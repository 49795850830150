@import "./seller-info";

.details__right {
  .description-shipping {
    padding: 25px 30px 30px;
  }
}

.wrapper-mob {
  .description {
    padding: 23px 14px;
  }
}

.description__main-txt {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: $white_font;

  @media (min-width: 769px) {
    font-size: 16px;
    line-height: 23px;
  }

  @media (max-width: 768px) {
    line-height: 20px;
  }

  @media (max-width: 424px) {
    font-size: 15px;
    line-height: 19px;
  }

  @media (max-width: 374px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.description__conditions {
  margin-top: 28px;
  color: $white_font;
}

.description__conditions-item {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 14px;
    @media (max-width: 768px) {
      &:first-child {
        margin-bottom: 25px;
      }
      margin-bottom: 34px;
    }
  }
  .car {
    svg {
      @media (max-width: 768px) {
        width: 15px;
        height: 12.86px;
      }
      @media (max-width: 374px) {
        width: 12px;
        height: 10.29px;
      }
    }
  }

  .warning {
    svg {
      @media (max-width: 768px) {
        width: 15px;
        height: 15px;
      }
      @media (max-width: 374px) {
        width: 12px;
        height: 12px;
      }
    }
  }

  .icon-holder {
    width: 30px;
  }

  .bullet {
    line-height: 15px;
  }
}

.description__seller-text {
  margin-top: 17px;
  padding-left: 30px;
  color: $grey_font;

  span {
    display: block;
    line-height: 15px;

    @media (max-width: 768px) {
      line-height: 20px;
    }

    @media (max-width: 424px) {
      line-height: 19px;
    }

    @media (max-width: 374px) {
      line-height: 16px;
    }
  }
}

.description__conditions,
.description__seller-text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: $desctop_size_font_p;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 424px) {
    font-size: 15px;
  }

  @media (max-width: 374px) {
    font-size: 12px;
  }
}
