.page-top {
  position: fixed;
  top: 104px;
  height: 87px;
  width: 100%;
  background: #0b0c23;
  overflow: hidden;
  z-index: 404;


  @media (max-width: 374px) {
    top: 88px;
    height: 64px;
  }
}

.page-top_auth {
  height: 113px;
}

.page-top__title {
  text-align: center;
  line-height: 1.1;
}
