.container {
  position: relative;
  width: 100%;
  background-color: $secondary_bg-sec;
}

.container__inner {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid $secondary_bg;
}