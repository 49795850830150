.main-block {
  position: relative;
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: 425px) {
    height: 80px;
  }

  @media (max-width: 424px) {
    height: 75px;
  }

  @media (max-width: 374px) {
    height: 65px;
  }

  .main-block__inner {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: $secondary_bg;

    .left {
      display: flex;
      flex-grow: 1;
      padding-top: 20px;

      @media (max-width: 374px) {
        padding-top: 18px;
      }

      .icon {
        width: 45px;
        text-align: center;

        svg {
          height: auto;

          @media (min-width: 425px) {
            width: 15px;
          }

          @media (max-width: 424px) {
            width: 13px;
          }

          @media (max-width: 374px) {
            width: 11px;
          }
        }
      }

      .text {
        flex-grow: 1;

        span {
          line-height: 25px;

          @media (max-width: 374px) {
            line-height: 23px;
          }
        }
      }
    }

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 10px;

      .action-box {
        display: flex;
        padding: 5px;
      }
    }
  }

  .social-icon__big svg {
    width: 17px !important;

    @media (max-width: 424px) {
      width: 15px !important;
    }

    @media (max-width: 374px) {
      width: 13px !important;
    }
  }

  .social-icon__facebook svg {
    width: 8px !important;

    @media (max-width: 424px) {
      width: 7px !important;
    }

    @media (max-width: 374px) {
      width: 6px !important;
    }
  }
}
