.general {
  display: flex;
  height: 100%;
  margin: 0 90px;
}

.general__center {
  position: relative;
  flex-grow: 1;

  @media (min-width: 1440px) {
    padding-right: 31px;
  }

  @media (max-width: 1439px) {
    padding-right: 0;
  }
}

.wrapper-mob {
  padding: 0 15px 15px;
}

.home-mob {
  padding-right: 15px;
}
