.product-slider-mob-controls {
  margin-left: 15px;

  .arrow svg {
    width: 12px;
    height: 7px;

    @media (max-width: 374px) {
      width: 10px;
      height: 6px;
    }
  }

  .arrow_left {
    transform: rotate(90deg);
  }

  .arrow_right {
    transform: rotate(-90deg);
  }
}

.products-slider-mob {
  position: relative;
  top: 15px;
  left: -15px;
  width: calc(100% + 15px);
  padding-bottom: 100%;

  .products-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .products__item-wrapper_mobile {
    height: 100%;
  }
}

.products-slider {
  @media (min-width: 769px) {
    display: grid;
    max-width: 243px;
    grid-gap: 30px;
  }
}
