.carousel-menu {
  position: absolute;
  top: 0;
  left: 50%;

  @media (min-width: 769px) {
    display: none;
  }

  .main-menu__items li {
    font-family: "Exo", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.3);

    @media (max-width: 768px) {
      padding: 0 28px;
      font-size: $mob_h2_425;
      line-height: 1.1;
    }

    @media (max-width: 424px) {
      font-size: $mob_h2_375;
    }

    @media (max-width: 374px) {
      padding: 0 22px;
      font-size: $mob_h2_320;
    }
  }

  .main-menu__items {
    .active {
      color: $white_font;
    }
  }
}
