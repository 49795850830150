.cart__content {
  .item {
    display: flex;

    @media (max-width: 768px) {
      &:last-child {
        margin-bottom: 85px;

        @media (max-width: 424px) {
          margin-bottom: 80px;
        }

        @media (max-width: 374px) {
          margin-bottom: 70px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 44px;

      @media (max-width: 768px) {
        margin-bottom: 60px;
      }

      @media (max-width: 374px) {
        margin-bottom: 44px;
      }

      .right-side::after {
        content: "";
        position: absolute;
        bottom: -22px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(196, 196, 196, 0.2);

        @media (max-width: 768px) {
          bottom: -30px;
        }

        @media (max-width: 374px) {
          bottom: -22px;
        }
      }
    }

    .left-side {
      position: relative;
      width: 22.8%;
      padding-bottom: 22.8%;

      @media (min-width: 769px) {
        width: 68px;
        padding-bottom: 68px;
      }

      .left-side__inner {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .container {
        height: 100%;
      }

      .container__inner {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 39px;
        }
      }

      .item-button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);

        svg {
          width: 8px;
          height: 9px;

          @media (max-width: 768px) {
            width: 10px;
            height: 12px;
          }

          @media (max-width: 424px) {
            width: 9px;
            height: 10.5px;
          }

          @media (max-width: 374px) {
            width: 8px;
            height: 9px;
          }
        }
      }
    }

    .right-side {
      position: relative;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      margin-left: 11%;

      .item__price {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: $white_font;

        @media (max-width: 768px) {
          font-size: 24px;
        }

        @media (max-width: 424px) {
          font-size: 20px;
        }

        @media (max-width: 374px) {
          font-size: 16px;
        }
      }

      .item__txt {
        font-weight: 300;
        color: $grey_font;
      }
    }

    .item__description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .item__controls {
      display: flex;
      align-items: center;
      margin-left: 15px;

      .counter {
        width: 33px;
        text-align: center;
        @include fontN2($white_font, 400);
      }
    }

    .item-button .square-btn {
      width: 34px;
      height: 34px;

      &:hover {
       background-color:$btn-main_bg-active;
      }

      @media (max-width: 768px) {
        width: 45px;
        height: 45px;
      }

      @media (max-width: 424px) {
        width: 40px;
        height: 40px;
      }

      @media (max-width: 374px) {
        width: 34px;
        height: 34px;
      }
    }
  }
}
