.cart-empty {
	.container {
		height: 90px;

		@media (max-width: 424px) {
			height: 80px;
		}

		@media (max-width: 374px) {
			height: 70px;
		}
	}

	.container__inner {
		padding: 30px 14px 0;

		@media (max-width: 374px) {
			padding-top: 25px;
		}
	}

	.content {
		display: flex;
		align-items: center;

		span {
			margin-left: 15px;
		}
	}

	svg {
		width: 23.5px;
		height: 25px;

		@media (max-width: 424px) {
			width: 21px;
			height: 22px;
		}

		@media (max-width: 374px) {
			width: 18px;
			height: 19px;
		}
	}
}
