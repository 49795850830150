html,
body,
#root {
  @media (min-width: 769px) {
    height: 100vh;
  }
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  box-sizing: border-box;
  background-color: $main_bg;
}

*::-webkit-scrollbar {
  width: 0;
}

* {
  scrollbar-width: none;
}

h2 {
  font-family: "Exo", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: $white_font;
  line-height: 0.9;

  @media (min-width: 769px) {
    font-size: $desctop_h2_size;
  }

  @media (max-width: 768px) {
    font-size: $mob_h2_425;
  }

  @media (max-width: 424px) {
    font-size: $mob_h2_375;
  }

  @media (max-width: 374px) {
    font-size: $mob_h2_320;
  }
}

h3 {
  @include exo_font($white_font, 20px, 500);

  @media (max-width: 768px) {
    @include exo_font($white_font, 24px, 500);
  }

  @media (max-width: 424px) {
    @include exo_font($white_font, 20px, 500);
  }

  @media (max-width: 374px) {
    @include exo_font($white_font, 16px, 500);
  }
}

input {
  padding: 0;
  border: 0;
  outline: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $white_font;
  background-color: rgba(0, 0, 0, 0);

  &::placeholder {
    color: $grey_font;
  }

  @media (min-width: 769px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 424px) {
    font-size: 15px;
  }

  @media (max-width: 374px) {
    font-size: 12px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  &:focus {
    outline: none;
  }
}

.main {
  margin-top: 195px;

  @media (min-width: 769px) {
    height: 100%;
    margin-top: 120px;
    flex-grow: 1;
    overflow: hidden;
  }

  @media (max-width: 424px) {
    margin-top: 191px;
  }
}

.main-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}

.main-body {
  height: auto;
  position: relative;
  z-index: 5;

  @media (min-width: 769px) {
    height: calc(100% - 50px);
    @include open-sans_font($grey_font, 12px);
  }
}

.body-block {
  overflow: hidden;
}
