.sidebar-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.sidebar {
  position: absolute;
  display: flex;
  top: 78px;
  left: 0;
  width: 100%;
  height: calc(100% - 78px);
  background-color: rgba(11, 12, 35, 0.98);
  z-index: 405;
}

.sidebar__menu {
  overflow: hidden;

  @media (min-width: 425px) {
    margin-top: 80px;
  }

  @media (max-width: 424px) {
    margin-top: 76px;
  }

  @media (max-width: 374px) {
    margin-top: 66px;
  }
}

.sidebar__nav {
  height: 100%;
  margin-right: -7px;
  overflow-y: scroll;

  @media (min-width: 425px) {
    width: 89px;
  }

  @media (max-width: 424px) {
    width: 83px;
  }

  @media (max-width: 374px) {
    width: 73px;
  }

  .active {
    background-color: $btn-main_bg;
  }
}

.sidebar__nav-inner {
  .menu-separate {
    display: flex;
    height: 2px;

    @media (min-width: 425px) {
      width: 82px;
    }

    @media (max-width: 424px) {
      width: 76px;
    }

    @media (max-width: 374px) {
      width: 66px;
    }

    svg {
      margin: auto;
    }
  }
}

.sidebar__nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 425px) {
    width: 82px;
    height: 78px;
  }

  @media (max-width: 424px) {
    width: 76px;
    height: 72px;
  }

  @media (max-width: 374px) {
    width: 66px;
    height: 62px;
  }

  .sidebar__nav-item-btn {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
}
