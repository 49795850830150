.coin-info {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 15px;
  background-color: $secondary_bg-sec;

  @media (min-width: 769px) {
    width: 232px;
    height: 100%;
    margin: 0;
    padding: 30px 15px 0 30px;
    border-left: 1px solid $secondary_bg;
    background-color: rgba(0, 0, 0, 0);
  }
}

.coin-info__header,
.coin-info__body {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 769px) {
    grid-gap: 10px;
  }

  @media (max-width: 374px) {
    grid-template-columns: 1fr;
  }
}

.coin-info__body {
  @include standart_font($white_font, 400);
  border-top: 1px solid $secondary_bg;
  border-left: 1px solid $secondary_bg;

  @media (min-width: 769px) {
    div {
      display: flex;
      align-items: flex-end;
    }
    margin-top: 28px;
    @include open-sans_font($white_font, 11px);
    border: 0;
  }

  dl {
    padding: 21px 14px;
    border-right: 1px solid $secondary_bg;
    border-bottom: 1px solid $secondary_bg;

    @media (min-width: 769px) {
      padding: 0;
      border: 0;
    }

    @media (max-width: 424px) {
      padding: 18px 15px;
    }
  }

  dd,
  dt {
    @media (min-width: 769px) {
      line-height: 13px;
    }

    @media (max-width: 768px) {
      line-height: 20px;
    }

    @media (max-width: 424px) {
      line-height: 19px;
    }

    @media (max-width: 374px) {
      line-height: 16px;
    }
  }

  dd {
    display: flex;
    align-items: flex-end;
    font-weight: 300;
    color: $grey_font;

    @media (min-width: 769px) {
      font-weight: initial;
    }
  }

  .growth {
    color: $green;
  }

  .fall {
    color: $red;
  }

  .prop-value {
    display: flex;
    align-items: center;
  }

  .volume-arrow {
    height: 10px;
    margin-left: 5px;
    @media (max-width: 374px) {
      margin-left: 8px;
    }
  }
}
