@mixin open-sans_font($color, $size) {
  font-family: "Open Sans", sans-serif;
  font-size: $size;
  font-style: normal;
  font-weight: 400;
  color: $color;
}

@mixin exo_font($color, $size, $weight) {
  font-family: "Exo", sans-serif;
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  color: $color;
}

@mixin standart_font($color, $weight) {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: $weight;
  color: $color;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 424px) {
    font-size: 15px;
  }

  @media (max-width: 374px) {
    font-size: 12px;
  }
}

@mixin fontN2($color, $weight) {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: $weight;
  color: $color;

  @media (max-width: 424px) {
    font-size: 11px;
  }

  @media (max-width: 374px) {
    font-size: 10px;
  }
}
