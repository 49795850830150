.action-place {
  position: relative;
  right: -14px;
  top: -1px;
  display: flex;
  width: 45px;
  height: 30px;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  .icon-holder {
    position: relative;
    right: 14px;
  }
}
