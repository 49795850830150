.coin-about {
  padding: 30px;

  @media (max-width: 768px) {
    padding: 29px 14px;
  }

  .coin-about__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .coin-info-holder {
      position: relative;
      display: none;
      margin-left: 30px;

      @media (max-width: 1919px) {
        display: block;
      }

      @media (max-width: 768px) {
        display: none;
      }

      & > h3 {
        cursor: pointer;
      }

      .drop-menu {
        @include drop_menu(210px, -142px, 154px);

        .close-cross {
          margin-top: 2px;
          svg {
          width: 12px;
          height: 12px;
          cursor: pointer;
          }
        }

        .drop-menu__box {
          padding-top: 20px;
        }

        @media (max-width: 1099px) {
          @include drop_menu(210px, -104px, 110px);
        }
      }
    }
  }

  .coin-about__header-left {
    display: flex;

    .price {
      @include exo_font($white_font, 15px, 400);

      @media (max-width: 768px) {
        font-size: 24px;
      }

      @media (max-width: 424px) {
        font-size: 20px;
      }

      @media (max-width: 374px) {
        font-size: 16px;
      }

      .price__unit {
        color: $grey_font;
      }
    }

    .coin-dynamics {
      display: none;
      margin-left: 6px;

      .growth {
        margin-right: 8px;
        @include exo_font($green, 10px, 400);

        @media (max-width: 768px) {
          margin-right: 11px;
          font-size: 12px;
        }

        @media (max-width: 424px) {
          font-size: 11px;
        }

        @media (max-width: 374px) {
          font-size: 10px;
        }
      }

      .growth_false {
        color: $red;
      }

      svg {
        @media (max-width: 768px) {
          width: 6px;
          height: 13px;
        }

        @media (max-width: 374px) {
          width: 5px;
          height: 11px;
        }
      }

      @media (min-width: 1440px) {
        display: block;
      }
      @media (max-width: 768px) {
        padding-top: 6px;
        display: block;
      }
      @media (max-width: 424px) {
        padding-top: 3px;
        font-size: 11px;
      }

      @media (max-width: 374px) {
        padding-top: 2px;
        font-size: 10px;
      }
    }
  }

  .coin-about__txt-block {
    margin-top: 23px;
    @include standart_font($grey_font, 400);
    line-height: 18px;

    @media (max-width: 768px) {
      line-height: 20px;
    }

    @media (max-width: 424px) {
      line-height: 19px;
    }

    @media (max-width: 374px) {
      line-height: 16px;
    }
  }

  .coin-about__params {
    margin-top: 54px;
    overflow: hidden;

    @media (max-width: 768px) {
      margin-top: 25px;
    }

    @media (max-width: 424px) {
      margin-top: 24px;
    }

    h3 {
      line-height: 25px;

      @media (max-width: 768px) {
        margin-top: 16px;
        line-height: 0;
      }
    }

    .profit {
      display: flex;
      margin-top: 28px;

      @media (max-width: 768px) {
        margin-top: 33px;
      }
    }

    .column {
      box-sizing: border-box;
      width: 104px;
      padding-left: 15px;
      &:first-child {
        max-width: 104px;
        min-width: 69px;
        width: 100%;
      }

      @media (max-width: 1439px) {
        width: auto;
        padding: 0 10px;
      }

      @media (max-width: 768px) {
        flex-grow: 1;
        padding-left: 15px;
      }

      .column__header {
        @include standart_font($white_font, 400);
      }

      .column__body {
        margin-top: 28px;

        @media (max-width: 768px) {
          margin-top: 18px;
        }
      }

      .column__decor {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -17px;
          box-sizing: border-box;
          width: 1px;
          height: 57px;
          background-color: rgba(218, 218, 218, 0.1);

          @media (max-width: 1439px) {
            left: -10px;
          }

          @media (max-width: 768px) {
            left: -15px;
            height: 60px;
          }

          @media (max-width: 374px) {
            height: 54px;
          }
        }
      }

      .column__item {
        @include open-sans_font($grey_font, 13px);

        @media (max-width: 768px) {
          font-size: 12px;
        }

        @media (max-width: 424px) {
          font-size: 11px;
        }

        @media (max-width: 374px) {
          font-size: 10px;
        }

        &:not(:first-child) {
          margin-top: 11px;

          @media (max-width: 768px) {
            margin-top: 13px;
          }
        }
      }

      .column__item_income {
        color: $white_font;
      }

      .column__item_electricity {
        color: $red;
      }

      .column__item_profit {
        color: $green;
      }
    }

    .column_head {
      width: 89px;
      padding: 0;

      @media (max-width: 768px) {
        width: auto;
      }
    }

    .column_main {
      @media (max-width: 1439px) {
        flex-grow: 1;
      }
    }

    .column_last {
      width: 107px;

      @media (max-width: 1439px) {
        width: auto;
        padding: 0 0 0 10px;
      }
    }

    .column_border-left {
      border-left: 1px solid rgba(218, 218, 218, 0.1);
    }

    .column_border-bottom {
      border-bottom: 1px solid rgba(218, 218, 218, 0.1);
    }

    .graph {
      margin-top: 28px;

      @media (max-width: 768px) {
        margin-top: 23px;
      }
      .graph__header {
        @include fontN2($white_font, 300);

        span {
          color: $green;
        }
      }

      .graph__inner {
        position: relative;
        display: flex;
        margin-top: 13px;
        @media (max-width: 768px) {
          margin-top: 13px;
        }
      }

      .graph__control-btn {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        width: 89px;
        height: 30px;
        border: 1px solid $secondary_bg;
        background-color: $secondary_bg-sec;

        .arrow-holder {
          width: 20px;
        }

        .arrow {
          @include arrow($grey_font);
        }

        .arrow-holder__left {
          left: 0;

          .arrow_left {
            left: 6px;
          }
        }

        .arrow-holder__right {
          right: 0;

          .arrow_right {
            right: 6px;
          }
        }

        .electricity-val {
          @include fontN2($white_font, 300);
        }
      }

      .grah__control-input {
        display: flex;
        box-sizing: border-box;
        width: 106px;
        height: 50px;
        align-items: center;
        @media (max-width: 768px) {
          padding: 0 15px;
        }

        @media (max-width: 374px) {
          width: 85px;
          height: 40px;
        }

        input {
          width: 100%;
          text-align: center;
          @include fontN2($white_font, 300);
        }
      }

      .column_border-bottom {
        width: 89px;

        @media (min-width: 769px) {
          width: 104px;
        }
      }

      .profit-graphic-holder {
        max-height: 30px;
        overflow: hidden;
        position: relative;
        width: calc(100% - 89px);
        border-bottom: 1px solid $secondary_bg-sec;

        @media (max-width: 768px) {
          width: calc(100% - 106px);
        }

        @media (max-width: 374px) {
          width: calc(100% - 85px);
        }

        .scale {
          position: absolute;
          top: 0;
          left: 0;
          display: grid;
          height: 100%;
          grid-template-columns: repeat(12, 104px);

          @media (max-width: 768px) {
            grid-template-columns: repeat(3, 88px);
          }

          .scale-item {
            border-right: 1px solid $secondary_bg-sec;
          }
        }

        .profit-graphic {
          position: absolute;
          bottom: -4px;
          left: -1px;
        }
      }
    }
  }

  .coin-about__dynamics {
    margin-top: 30px;

    @media (min-width: 769px) {
      margin-top: 58px;
    }

    .controls {
      display: flex;
      justify-content: space-between;

      .btns-wrapper {
        display: flex;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          @include fontN2($white_font, 400);

          &:not(:last-child) {
            margin-right: 15px;
          }

          @media (min-width: 769px) {
            background-color: transparent;
            @include open-sans_font($grey_font, 11px);
            cursor: pointer;
          }

          @media (max-width: 768px) {
            width: 55px;
            height: 55px;
          }

          @media (max-width: 424px) {
            width: 50px;
            height: 50px;
          }

          @media (max-width: 374px) {
            width: 40px;
            height: 40px;
          }
        }
      }

      .btns-wrapper__left {
        .icon-graph-line svg {
          width: 15px;
          height: 15px;

          @media (max-width: 768px) {
            width: 14px;
            height: 14px;
          }

          @media (max-width: 424px) {
            width: 13px;
            height: 13px;
          }

          @media (max-width: 374px) {
            width: 10px;
            height: 10px;
          }
        }

        .icon-graph-chart svg {
          width: 16px;
          height: 19px;

          @media (max-width: 768px) {
            width: 15px;
            height: 18px;
          }

          @media (max-width: 424px) {
            width: 13px;
            height: 16px;
          }

          @media (max-width: 374px) {
            width: 10px;
            height: 12px;
          }
        }
      }

      .btns-wrapper__right {
        .btn-main {
          color: $white_font;
        }
      }
    }

    .graphics {
      @include fontN2($white_font, 400);
      overflow: hidden;

      @media (min-width: 769px) {
        @include open-sans_font($white_font, 8px);
      }

      .graph-hourly {
        position: relative;
      }

      .graph-hourly__inner {
        .graph-hourly__item {
          display: flex;
          height: 57px;

          @media (min-width: 769px) {
            height: 30px;
          }

          .item-head {
            display: block;
            width: 50px;
            padding-top: 30px;
            color: $grey_font;

            @media (min-width: 769px) {
              padding-top: 14px;
            }

            @media (max-width: 768px) {
              width: 68px;
            }

            @media (max-width: 424px) {
              width: 64px;
            }
          }

          .item-body {
            box-sizing: border-box;
            flex-grow: 1;
            border-bottom: 1px solid $secondary_bg-sec;
          }
        }
      }

      .graph-hourly__scale-wrapper {
        position: absolute;
        right: 0;
        width: calc(100% - 50px);
        height: 20px;
        overflow: hidden;

        @media (max-width: 768px) {
          width: calc(100% - 68px);
          height: 25px;
        }

        @media (max-width: 424px) {
          width: calc(100% - 64px);
          height: 23px;
        }
      }

      .graph-hourly__scale {
        position: absolute;
        right: 46px;
        display: grid;
        grid-template-columns: repeat(26, 92px);

        @media (min-width: 769px) {
          right: 23px;
          grid-template-columns: repeat(50, 46px);
        }

        .scale-item {
          position: relative;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 5px;
            background-color: $secondary_bg-sec;
          }

          span {
            position: absolute;
            top: 11px;
            right: 0;
            transform: translateX(50%);
          }
        }
      }

      .graphics-holder {
        position: absolute;
        right: 0;
        width: calc(100% - 50px);
        height: 100%;
        overflow: hidden;

        @media (max-width: 768px) {
          width: calc(100% - 68px);
        }

        @media (max-width: 424px) {
          width: calc(100% - 64px);
        }

        .graphic {
          position: absolute;
          right: 0;
        }
      }

      .graphics-holder_top {
        top: 59px;

        @media (max-width: 768px) {
          top: 112px;
        }

        .graphic {
          top: 0;
        }
      }

      .graphics-holder_bottom {
        bottom: 1px;

        .graphic {
          bottom: 0;
        }
      }

      .graph-year {
        position: relative;
        height: 53px;
        margin-top: 54px;

        @media (min-width: 769px) {
          height: 43px;
          margin-top: 47px;
        }

        .graph-year__scale {
          position: absolute;
          right: -69px;
          display: grid;
          grid-template-columns: repeat(22, 138px);
          border-top: 1px solid $secondary_bg-sec;
          border-bottom: 1px solid $secondary_bg-sec;

          .scale-item {
            position: relative;
            height: 28px;
            border-right: 1px solid $secondary_bg-sec;

            span {
              position: absolute;
              left: -1px;
              bottom: -24px;
              transform: translateX(-50%);

              @media (min-width: 769px) {
                left: -1px;
                bottom: -14px;
                transform: none;
              }
            }
          }
        }

        .graphic {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
