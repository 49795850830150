.specification {
  position: relative;
  background-color: $secondary_bg-sec;
}

.specification__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 60px;
  border-top: 1px solid $secondary_bg;
  border-left: 1px solid $secondary_bg;

  @media (max-width: 1919px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-auto-rows: 80px;
  }

  @media (max-width: 424px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 75px;
  }

  @media (max-width: 374px) {
    grid-auto-rows: 65px;
  }
}

.specification__item {
  display: flex;
  padding: 15px;
  border-right: 1px solid $secondary_bg;
  border-bottom: 1px solid $secondary_bg;

  @media (max-width: 768px) {
    padding: 22px 15px;
  }

  @media (max-width: 424px) {
    padding: 20px 15px;
  }

  @media (max-width: 424px) {
    padding: 17px 15px;
  }

  .icon-holder {
    display: flex;
    width: 27px;

    @media (max-width: 374px) {
      width: 27px;
    }

    svg {
      width: 13px;

      @media (max-width: 768px) {
        width: 15px;
      }

      @media (max-width: 424px) {
        width: 13px;
      }

      @media (max-width: 374px) {
        width: 11px;
      }
    }
  }

  .specs {
    span {
      display: block;
    }

    .specs__name {
      @include standart_font($white_font, 400);

      @media (max-width: 424px) {
        line-height: 19px;
      }

      @media (max-width: 374px) {
        line-height: 16px;
      }
    }

    .specs__value {
      @include standart_font($grey_font, 400);
      line-height: 20px;

      @media (max-width: 768px) {
        line-height: 24px;
      }

      @media (max-width: 424px) {
        line-height: 19px;
      }

      @media (max-width: 374px) {
        line-height: 16px;
      }
    }
  }
}

.specification__item-plug {
  border-right: 1px solid $secondary_bg;
  border-bottom: 1px solid $secondary_bg;

  @media (min-width: 1920px) {
    display: none;
  }

  @media (max-width: 424px) {
    display: none;
  }
}
