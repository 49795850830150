.terms__close-btn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101;
  @media (max-width: 768px) {
    bottom: 28px;
  }
  cursor: pointer;
  a {
    display: flex;
  }

  svg {
    width: 20px;
    height: 20px;

    @media (max-width: 424px) {
      width: 17px;
      height: 17px;
    }

    @media (max-width: 374px) {
      width: 15px;
      height: 15px;
    }
  }
}

.terms-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  @media (min-width: 769px) {
    position: static;
  }
  @media (max-width: 374px) {
    top: -20px;
  }
}

.terms {
  position: absolute;
  top: 108px;
  width: 100%;
  height: calc(100% - 110px);

  @media (min-width: 769px) {
    position: relative;
    top: 78px;
    width: 642px;
    height: 100%;
    margin: auto;
  }

  .terms__content {
    @media (max-width: 768px) {
      padding: 0 30px;
    }
  }

  .terms__inner {
    height: 100%;
  }

  .terms__header {
    text-align: center;
    @media (min-width: 769px) {
      top: 170px;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .terms-body {
    position: relative;
    height: calc(100% - 80px);

    @media (min-width: 769px) {
      height: calc(100% - 78px);
      margin: 0;
      overflow: hidden;
    }

    .text-underline-desctop {
      @media (min-width: 769px) {
        text-decoration: underline;
      }
    }

    .terms__footer-gradient {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 78px;
      background: linear-gradient(180deg, rgba(11, 12, 35, 0) 0%, #0b0c23 100%);
      z-index: 41;
      @media (max-width: 768px) {
        bottom: 0;
      }
    }
  }

  .terms__title {
    margin: 0 auto;
    line-height: 0.9;

    @media (max-width: 768px) {
      margin-top: 55px;
      font-size: 13px;
    }

    @media (max-width: 424px) {
      font-size: 12px;
    }

    @media (max-width: 374px) {
      margin-top: 43px;
      font-size: 11px;
    }
  }

  .terms__subtitle {
    margin: 15px 0 55px;
    @include open-sans_font($white_font, 10px);
    font-weight: 300;
    text-align: center;
    line-height: 0.8;

    @media (max-width: 768px) {
      font-size: 12px;
    }

    @media (max-width: 424px) {
      font-size: 11px;
    }

    @media (max-width: 374px) {
      font-size: 10px;
      margin-bottom: 40px;
    }
  }

  .terms__head-line-height {
    line-height: 18px;

    @media (max-width: 768px) {
      line-height: 19px;
    }

    @media (max-width: 424px) {
      line-height: 18px;
    }

    @media (max-width: 374px) {
      line-height: 17px;
    }
  }

  h3 {
    max-width: 440px;
    margin: 53px auto 0;
    @include exo_font($white_font, $desctop_size_font, 500);
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 13px;
    }

    @media (max-width: 424px) {
      font-size: 12px;
    }

    @media (max-width: 374px) {
      font-size: 11px;
    }
  }

  h4 {
    margin-top: 55px;
    @include exo_font($white_font, $desctop_size_font, 500);
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 13px;
    }

    @media (max-width: 424px) {
      font-size: 12px;
    }

    @media (max-width: 374px) {
      font-size: 11px;
    }
  }

  ul {
    margin-left: 30px;
  }

  p,
  ul {
    margin-top: 20px;
    @include open-sans_font($white_font, 13px);
    font-weight: 300;
    line-height: 18px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }

    @media (max-width: 424px) {
      font-size: 13px;
      line-height: 19px;
    }

    @media (max-width: 374px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  a {
    text-decoration: underline;
  }

  .cascade-list {
    & > li {
      margin-top: 23px;
    }
    span {
      text-decoration: underline;
    }
  }
}
