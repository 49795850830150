.cart-checkout-mob {
  font-family: Open Sans;
  color: $white_font;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $main_bg;

  .cart-checkout-mob__inner {
    position: absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    top: 104px;
    width: 100%;
    height: calc(100% - 104px);
    background-color: rgba(11, 12, 35, 0.98);
    z-index: 70;

    @media (max-width: 374px) {
      top: 88px;
      height: calc(100% - 88px);
    }

    .page-top_hide {
      position: absolute;
      top: 0;
    }
  }

  .cart__content {
    position: relative;
    top: 91px;
    margin: 0 15px 0 15px;

    @media (max-width: 374px) {
      top: 64px;
    }

    .item-list {
      margin-bottom: 225px;
      position: relative;
      padding: 31px 14px 0 15px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.02);

      .quantity {
        padding-top: 29px;
        margin-bottom: 7px;
        border-top: 1px solid rgba(196, 196, 196, 0.2);
        text-align: center;
        font-size: 16px;
        span {
          color: $green;
        }
        @media (max-width: 768px) {
          font-size: 16px;
        }
        @media (max-width: 424px) {
          font-size: 15px;
        }
        @media (max-width: 374px) {
          font-size: 12px;
        }
      }

      .invoce {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 27px;
        @media (max-width: 768px) {
          font-size: 24px;
        }
        @media (max-width: 424px) {
          font-size: 20px;
        }
        @media (max-width: 374px) {
          font-size: 16px;
        }
      }
    }
  }

  .diamond-part {
    @include diamond_part(30px);
  }
}

.cart-checkout-mob__footer {
  background-color: $main_bg;
  position: fixed;
  bottom: 0;
  padding-top: 56px;
  p {
    line-height: 19px;
    margin: 0 38px 56px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    span {
      color: $green;
    }

    @media (max-width: 424px) {
      font-size: 16px;
    }

    @media (max-width: 374px) {
      font-size: 14px;
    }
  }
  .buttons-container {
    margin-bottom: 15px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    button {
      width: 100%;
      height: 55px;
      @media (max-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 424px) {
        font-size: 15px;
        height: 50px;
      }

      @media (max-width: 374px) {
        font-size: 12px;
        height: 40px;
      }
    }
    button:first-child {
      margin-right: 16px;
    }
  }
}
