.seller-info-header {
  margin-top: 57px;
}

.seller-info {
  padding: 14px 24px;

  @media (min-width: 769px) {
    display: flex;
    margin-top: 28px;
    padding: 0;
    justify-content: space-between;
    flex-flow: wrap;
  }
}

.seller-info__person {
  position: relative;
  padding-bottom: 26px;

  @media (max-width: 1768px) {
    padding-bottom: 30px;
  }

  @media (min-width: 769px) {
    display: flex;
    margin-right: 25px;
    padding-bottom: 15px;
  }

  .seller-img-holder {
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 769px) {
      left: initial;
      transform: initial;
      margin-right: 20px;
    }
  }

  .seller-img-holder__ava {
    width: 40px;
    height: 40px;
    display: block;
    margin: auto;
    border-radius: 50%;
    background-size: cover;
    @media (min-width: 769px) {
      margin: 0;
    }
    @media (max-width: 768px) {
      width: 74px;
      height: 74px;
    }
    @media (max-width: 424px) {
      width: 66px;
      height: 66px;
    }
    @media (max-width: 374px) {
      width: 57px;
      height: 57px;
    }
  }

  .seller-img-holder__chat {
    position: absolute;
    top: 57%;
    right: -11%;
    cursor: pointer;

    @media (min-width: 769px) {
      right: -20%;
    }
    svg {
      @media (max-width: 768px) {
        width: 37px;
        height: 37px;
      }
      @media (max-width: 424px) {
        width: 32px;
        height: 32px;
      }
      @media (max-width: 374px) {
        width: 27px;
        height: 27px;
      }
    }
  }

  .seller-rating-holder {
    padding-top: 13px;
    text-align: center;

    @media (min-width: 769px) {
      padding-top: 5px;
    }
  }

  .seller-rating-holder__name {
    position: relative;

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
    }

    .name {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: $desctop_size_font_p;
      color: $white_font;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 424px) {
        font-size: 15px;
      }

      @media (max-width: 374px) {
        font-size: 12px;
      }
    }

    .verified {
      display: flex;
      align-items: flex-end;
      margin-left: 10px;
      margin-bottom: 2px;

      @media (min-width: 769px) {
        position: absolute;
        display: flex;
        top: 25%;
        right: -17px;
        margin: 0;
      }
      @media (max-width: 374px) {
        margin-bottom: 1px;
        margin-left: 4px;
      }
      svg {
        @media (max-width: 424px) {
          width: 9px;
          height: 9px;
        }
        @media (max-width: 374px) {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .seller-rating-holder__stars {
    margin-top: 4px;
    margin-left: 2px;
    background-image: url("../../../../assets/img/stars.svg");
    background-size: cover;
    width: 65px;
    height: 10px;
    @media (max-width: 768px) {
      margin: 15px auto 0;
      width: 85px;
      height: 13px;
    }

    @media (max-width: 424px) {
      width: 75px;
      height: 11px;
    }

    @media (max-width: 374px) {
      width: 65px;
      height: 9px;
    }
  }
}

.seller-info__data {
  display: flex;
  align-self: flex-end;
  @media (max-width: 1116px) {
   margin-left: 20px;
  }
  @media (max-width: 768px) {
    margin-left: 0;
   }

  .seller-info__data-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 1116px) {
      margin-top: 15px;
      justify-content: center;
    }
    @media (max-width: 768px) {
      margin-top: 0;
      justify-content: space-between;
    }
  }

  .seller-info__data-item {
    &:not(:last-child) {
      margin-right: 10px;
    }

    @media (min-width: 769px) {
      &:not(:last-child) {
        margin-right: 36px;
      }
    }
    @media (max-width: 1116px) {
      width: 43%;
      &:not(:last-child) {
        margin-right: 0;
      }
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 30px;
      }
    }
    @media (max-width: 768px) {
      width: auto;
      &:not(:last-child) {
        margin-right: 10px;
      }
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 0;
      }
    }

    span {
      display: block;
    }

    .item__value {
      font-family: "Exo", sans-serif;
      text-align: center;
      color: $white_font;
      font-size: 32px;
      font-weight: 400;

      @media (max-width: 424px) {
        font-size: 26px;
      }

      @media (max-width: 374px) {
        font-size: 22px;
      }
    }

    .item__caption {
      margin-top: 6px;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 10px;
      color: $grey_font;

      @media (max-width: 768px) {
        font-size: 12px;
      }

      @media (max-width: 424px) {
        font-size: 11px;
      }

      @media (max-width: 374px) {
        font-size: 10px;
      }
    }
  }
}
