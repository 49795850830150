.main-menu__items {
  display: flex;
  align-items: center;

  .main-menu__items-separator {
    margin: 0 30px;
  }

  & li:hover {
    color: $text_hover;
  }
}

.main-menu__items li {
  cursor: pointer;
}

.overlay-menu {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: rgba(11, 12, 35, 0.98);
  z-index: 5000;
  overflow: hidden;

  button {
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .main-menu__wrapper {
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 80%;
  }

  .main-menu__items {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include exo_font($white_font, 30px, 500);

    li {
      @media (max-height: 1023px) {
        font-size: 24px;
      }
    }

    li:not(:first-child):not(:last-child) {
      display: flex;
      align-items: center;
      height: 16.5%;
    }

    li:first-child {
      height: 10%;
    }

    li:last-child {
      display: flex;
      align-items: flex-end;
      height: 10.5%;
    }

    .main-menu__items-separator {
      transform: rotate(90deg);
    }
  }

  .overlay-menu-background {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(45deg) translate(-50px, 395px);
    @include exo_font(rgba(255, 255, 255, 0.02), 500px, bold);
    text-transform: lowercase;
    z-index: -1;
  }
}
