.page-not-found {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    font-family: "Open Sans", sans-serif;
    color: $white_font;
    font-size: 12px;

    background-color: $main_bg;
    @media (max-width: 768px) {
        padding: 0 48px;
    }
    @media (max-width: 424px) {
        padding: 0 33px;
    }
    @media (max-width: 374px) {
        padding: 0 36px;
    }

    p {
        margin: 52px 0 55px;
        font-size: 12px;
        @media (max-width: 768px) {
            font-size: 16px;
        }
        @media (max-width: 424px) {
            font-size: 15px;
        }
        @media (max-width: 374px) {
            font-size: 12px;
        }
    }

    button {
        font-size: 12px;
        height: 30px;
        width: 98px;
        @media (max-width: 768px) {
            font-size: 16px;
            height: 55px;
            width: 189px;
        }
        @media (max-width: 424px) {
            font-size: 15px;
            height: 50px;
            width: 172px;
        }
        @media (max-width: 374px) {
            font-size: 12px;
            height: 40px;
            width: 138px;
        }
    }
}
