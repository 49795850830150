.pdp-controls-wrapper {
  position: sticky;
  display: inline-block;
  top: 0;
  left: 100%;
  z-index: 12;
}

.pdp-controls {
  display: flex;
  color: $white_font;
  font-family: "Open Sans", sans-serif;

  nav {
    margin-right: 30px;
    @media (max-width: 1080px) {
      display: none;
    }
  }

  button {
    @include open-sans_font($white_font, 12px);
    cursor: pointer;
  }

  .arrow-left {
    margin-right: 8px;

    svg {
      transform: rotate(90deg);
    }
  }

  .arrow-right {
    margin-left: 8px;

    svg {
      transform: rotate(-90deg);
    }
  }

  .pdp-close-btn {
    display: flex;
  }

  .pdp-close-btn__txt {
    margin-right: 10px;
  }
}
