$white_font: #fff;
$grey_font: #c4c4c4;
$text_hover: #888;

$desctop_size_font: 12px;
$desctop_size_font_p: 13px;
$desctop_h2_size: 24px;
$mob_h2_425: 36px;
$mob_h2_375: 30px;
$mob_h2_320: 26px;

$green: #00a651;
$red: #ed1c24;

$main_bg: #0b0c23;
$secondary_bg: rgba(255, 255, 255, 0.1);
$secondary_bg-hex: #242539;
$secondary_bg-sec: rgba(255, 255, 255, 0.05);
$secondary_bg-sec-hex: #17182e;

$hover_block_bg: rgba(11, 12, 35, 0.3);

$popup_bg: rgba(23, 24, 46, 0.98);

$btn-main_bg: rgb(27, 27, 103);
$btn-main_bg-active: rgb(38, 38, 143);
$btn-dark_bg: rgba(11, 12, 35, 0.8);
