.rolling-block {
  position: relative;

  .main-block {
    right: 15px;
    width: calc(100% + 15px);
    overflow: hidden;

    .main-block__inner {
      position: absolute;
      right: 0;
      width: calc(100% - 15px);
      border: 0;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .rolling-block__shown {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    background-color: $secondary_bg;
  }

  .rolling-block__hidden {
    position: absolute;
    top: 0;
    display: flex;
    box-sizing: border-box;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $secondary_bg;
    border-top: 1px solid $secondary_bg;
    border-bottom: 1px solid $secondary_bg;

    @media (min-width: 425px) {
      width: 80px;
      right: -80px;
    }

    @media (max-width: 424px) {
      width: 75px;
      right: -75px;
    }

    @media (max-width: 374px) {
      width: 65px;
      right: -65px;
    }

    .rolling-block__button {
      padding: 5px;
    }
  }
}
