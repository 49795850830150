.select {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.dot {
  position: relative;
  height: 100%;
}

.select__shown-area {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  border: 1px solid $secondary_bg;
  background-color: rgba(23, 24, 46, 0.8);
  @media (max-width: 768px) {
    height: 55px;
  }

  @media (max-width: 424px) {
    height: 50px;
  }

  @media (max-width: 374px) {
    height: 40px;
  }
}

.select__show-btn {
  right: 20px;
  position: absolute;
  cursor: pointer;
}

.select__show {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 9px 10px 13px;
  border: 1px solid $secondary_bg;
}

.select__wrapper {
  box-sizing: border-box;
  position: relative;
  background-color: rgb(23, 24, 46);
  @media (min-width: 769px) {
    top: -136px;
    z-index: 9999;
  }
}

.min-items {
  @media (min-width: 769px) {
    top: -107px;
  }
}

.select__list {
  max-height: 93px;
  overflow-y: scroll;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $secondary_bg;

  @media (max-width: 768px) {
    max-height: calc(100vh - 100px);
    margin-top: 15px;
  }

  li {
    padding-left: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 30px;
    color: $grey_font;

    @media (max-width: 768px) {
      color: $white_font;
      height: 55px;
    }

    @media (max-width: 424px) {
      height: 50px;
    }

    @media (max-width: 374px) {
      height: 40px;
    }
    &:hover {
      color: $white_font;
    }
  }
  .active {
    color: $white_font;
    background-color: #26268e;
  }
}
