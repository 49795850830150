.products__item-wrapper_mobile {
  overflow: hidden;
  .item-holder {
    position: absolute;
    left: 15px;
    width: calc(100% - 15px);
    height: 100%;
    background-color: $secondary_bg-hex;
  }

  .item-holder_ads {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 38%;
      height: auto;
    }
  }

  .products__item {
    left: -15px;
    width: calc(100% + 15px);
    padding: 0;
    border-left: 0;
    border-right: 1px solid rgba(0, 0, 0, 0);
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    background-color: transparent;
    overflow: unset;
  }

  .products__item_active {
    border-right-color: $secondary_bg-hex;
    border-top-color: $secondary_bg-hex;
    border-bottom-color: $secondary_bg-hex;
  }

  .products__item-inner {
    position: absolute;
    right: 0;
    width: calc(100% - 16px);
    overflow: unset;
  }

  .products__item-show-block {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: $secondary_bg-hex;

    .products__item-open-order {
      position: absolute;
      display: flex;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: calc(100% - 160px);
      width: 70px;
      justify-content: center;
      align-items: center;

      & svg {
        @media (min-width: 425px) {
          width: 10px;
          height: 10px;
        }

        @media (max-width: 424px) {
          width: 9px;
          height: 9px;
        }

        @media (max-width: 374px) {
          width: 8px;
          height: 8px;
        }
      }
    }

    .products__item-close-order {
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 111;

      & svg {
        position: absolute;
        top: 50%;

        transform: translateY(-50%) rotate(180deg);

        @media (min-width: 425px) {
          right: 20%;
          width: 20px;
          height: 20px;
        }

        @media (max-width: 424px) {
          right: 18%;
          width: 18px;
          height: 18px;
        }

        @media (max-width: 374px) {
          right: 15.5%;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .order-mob {
    position: absolute;
    top: 0;
    right: -219px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 218px;
    height: 100%;
    padding: 0 15px 15px;
    background-color: $main_bg;

    @media (max-width: 374px) {
      right: -199px;
      width: 199px;
    }

    .order-mob__price-shown {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include open-sans_font($white_font, 24px);

      @media (max-width: 424px) {
        font-size: 20px;
      }

      @media (max-width: 374px) {
        font-size: 16px;
      }

      .order-mob__price-symb {
        color: $grey_font;
        font-weight: 300;
      }

      .order-mob__price {
        margin-top: 10px;
        font-weight: 700;
      }
    }

    .select-wrapper {
      margin: 0 0 30px;

      width: 100%;

      @media (min-width: 425px) {
        height: 55px;
      }

      @media (max-width: 424px) {
        height: 50px;
      }

      @media (max-width: 374px) {
        height: 40px;
      }
    }

    .order-mob__btn_details {
      margin-bottom: 15px;
    }

    .corner-bottom-right {
      bottom: -1px;
      right: -1px;
    }

    .corner-top-right {
      right: -1px;
      top: -1px;
    }
  }
}

.products__item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
