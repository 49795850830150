.toggle-block {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.toggle-block__inner {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 15px 14px 0;
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: $secondary_bg;

  @media (max-width: 768px) {
    padding: 20px 14px 0;
  }

  @media (max-width: 374px) {
    padding: 17px 14px 0;
  }
}
