.social__link {
  @include block_centered_content(30px, 30px, $secondary_bg);

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:hover {
    background-color: $btn-main_bg;
  }
}