.main-header__ml-30 {
  margin-left: 30px;
}

.header-controls {
  display: flex;
}

.onsale {
  @media (min-width: 769px) {
    overflow: hidden;
  }

  .products__item-wrapper {
    width: 100%;
    padding-bottom: calc(100% - 15px);

    @media (max-width: 768px) {
      margin-bottom: 15px;
    }

    @media (min-width: 769px) {
      width: auto;
      padding: 0;
    }
  }
}

.products {
  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, 213px);
    grid-gap: 30px;
    margin-left: 30px;
    overflow-x: hidden;
  }
}
