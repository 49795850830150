// Decor Arrow
.decor-arrow {
  position: absolute;
  top: -6px;
  right: 50%;
}

// Diamond part
@mixin diamond_part($diamond_right) {
  position: absolute;
  top: -6px;
  right: $diamond_right;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  border-top: 1px solid $grey_font;
  border-right: 1px solid $grey_font;

  .diamond-bg {
    position: absolute;
    top: -8px;
    left: 2px;
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #17182e;
    transform: rotate(45deg);
  }
}

//*** Box Decor

$pos: 0;
@mixin box_decor($dim) {
  position: absolute;
  width: $dim;
  height: $dim;
  background-color: #dadada;
}

.corner-top-left {
  @include box_decor(1px);
  top: $pos;
  left: $pos;
}

.corner-top-right {
  @include box_decor(1px);
  top: $pos;
  right: $pos;
}

.corner-bottom-left {
  @include box_decor(1px);
  bottom: $pos;
  left: $pos;
}

.corner-bottom-right {
  @include box_decor(1px);
  bottom: $pos;
  right: $pos;
}

//*** ENDBox Decor
