.list-head-wrapper {
  position: absolute;
  width: 100%;
  height: 45px;
  background-color: $main_bg;
  z-index: 11;

  .list__price {
    margin-right: 30px;
    text-align: right;
  }

  .list__manufacturer {
    @media (max-width: 1439px) {
      text-align: left;
    }
  }
}

.list-inner-header {
  box-sizing: border-box;
  height: 30px;
  padding: 0 15px;
  border: 1px solid $secondary_bg;
  font-weight: 400;
  color: $white_font;
  background-color: $secondary_bg-sec;
}

// Item
.list-item-wrapper {
  .select-wrapper {
    position: relative;
    height: auto !important;
    overflow: hidden;
  }

  .select {
    height: auto;
    .select__shown-area {
      height: 40px;
    }
    .select__wrapper {
      top: 0;
      margin-top: 15px;
    }
    .select__list {
      max-height: 122px;
      height: 100%;
    }
  }


  position: relative;
  width: 100%;
  height: 40px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .list-item-carriage {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .list-item-inner {
    box-sizing: border-box;
    height: 100%;
    padding: 0 15px;
    border: 1px solid rgba(255, 255, 255, 0);
  }

  .order {
    position: absolute;
    top: 0;
    right: -60%;
    height: 100%;
  }

  .select-wrapper {
    width: 180px;
    margin: 0 15px;
  }
}

.list {
  display: grid;
  box-sizing: border-box;
  height: 100%;
  align-items: center;

  @media (min-width: 1920px) {
    grid-template-columns: 29px 35px repeat(10, 1fr);
  }

  @media (max-width: 1919px) {
    grid-template-columns: 29px 20px 9% 14% 9% 15% 13% 9% 12% 1fr;
  }

  @media (max-width: 1439px) {
    grid-template-columns: 29px 20px 16% 8% 24% 12% 14% 1fr;
  }
}

.list__release {
  text-align: left;

  @media (max-width: 1439px) {
    display: none;
  }
}

.list__manufacturer {
  text-align: center;

  @media (max-width: 1439px) {
    text-align: left;
  }
}

.list__model {
  text-align: left;
}

.list__hash {
  text-align: center;
}

.list__noise {
  text-align: center;

  @media (max-width: 1919px) {
    display: none;
  }
}

.list__power {
  text-align: center;

  @media (max-width: 1919px) {
    display: none;
  }
}

.list__algorithm {
  text-align: left;

  @media (max-width: 1439px) {
    display: none;
  }
}

.list__efficiency {
  text-align: left;
}

.list__profit {
  text-align: left;
}

.list__price {
  text-align: right;
  font-weight: 400;
  color: $white_font;
}
