.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute !important;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.overflow-hidden {
  overflow: hidden;
}

.line-height-8 {
  line-height: 0.8;
}

@media (max-width: 1919px) {
  .responsive-1919 {
    display: none;
  }
}

.main-font {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: $white_font;

  @media (min-width: 769px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 424px) {
    font-size: 15px;
  }

  @media (max-width: 374px) {
    font-size: 12px;
  }
}

.main-line-height {
  line-height: 15px;

  @media (max-width: 768px) {
    line-height: 20px;
  }

  @media (max-width: 424px) {
    line-height: 19px;
  }

  @media (max-width: 374px) {
    line-height: 16px;
  }
}

.h4-mob {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  color: $white_font;
}

.span-mob,
.p-mob {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $grey_font;
}

.h4-mob,
.p-mob,
.span-mob {
  text-transform: none;

  @media (min-width: 425px) {
    font-size: 16px;
  }

  @media (max-width: 424px) {
    font-size: 15px;
  }

  @media (max-width: 374px) {
    font-size: 12px;
  }
}

.input-holder {
  @media (min-width: 769px) {
    height: 30px;
  }

  @media (max-width: 768px) {
    height: 55px;

    input {
      width: 100%;
    }
  }

  @media (max-width: 424px) {
    height: 50px;
  }

  @media (max-width: 374px) {
    height: 40px;
  }

  input {
    cursor: default;
  }
}

.decor-box {
  position: relative;
  width: 100%;

  .decor-box__inner {
    display: flex;
    box-sizing: border-box;
    height: 100%;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: $grey_font;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: $secondary_bg;

    @media (min-width: 769px) {
      height: 30px;
    }

    @media (max-width: 768px) {
      height: 55px;
    }

    @media (max-width: 424px) {
      height: 50px;
    }

    @media (max-width: 374px) {
      height: 40px;
    }

    input {
      width: 100%;
      text-align: center;
    }

    span {
      font-size: 10px;

      @media (max-width: 768px) {
        font-size: 12px;
      }

      @media (max-width: 424px) {
        font-size: 11px;
      }

      @media (max-width: 374px) {
        font-size: 10px;
      }
    }

    .info-input {
      color: transparent;
      cursor: default;

      &::placeholder {
        @media (max-width: 768px) {
          font-size: 16px;
        }

        @media (max-width: 424px) {
          font-size: 15px;
        }

        @media (max-width: 374px) {
          font-size: 12px;
        }
      }
    }

    .info-input_active::placeholder {
      color: $white_font;
    }
  }
}

.item-active {
  border: 1px solid $secondary_bg !important;
  color: $white_font !important;
  background-color: $secondary_bg-sec !important;

  .span-mob {
    color: $white_font;
  }
}

.grid-template-2fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.update-icon svg {
  @media (max-width: 768px) {
    width: 13px;
  }

  @media (max-width: 424px) {
    width: 12px;
  }

  @media (max-width: 374px) {
    width: 11px;
  }
}
