.general__center {
  .details {
    display: flex;
    height: 100%;
  }

  .details__left,
  .details__right {
    margin-left: 30px;
  }

  .scroll-layer {
    @media (max-width: 1439px) {
      display: none;
    }
  }

  .on-sale__wrapper {
    overflow-y: scroll;
    min-width: 213px;
    height: calc(100% - 50px);

    .products__item {
      background-color: hsla(0, 0%, 100%, 0.05);
      border: 1px solid hsla(0, 0%, 100%, 0.1);
    }
  }

  .details__right {
    position: relative;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;

    .details__main-inner {
      position: relative;
      top: -18px;

      section:last-child {
        .content-wrapper {
          padding-bottom: 42px;
        }
      }
    }

    .content-wrapper {
      padding-bottom: 60px;
    }
  }

  .details__footer section:last-child {
    .content-wrapper {
      padding-bottom: 0;
    }
  }
}

.magic-header {
  top: 0;
  position: sticky;
  width: calc(100% + 1px);
  height: 48px;
  background-color: $main_bg;
  z-index: 11;

  .pdp-controls {
    nav {
      margin: 0;
    }
  }
}

.wrapper-mob {
  .details {
    margin-bottom: 29px;

    &:last-child,
    &:nth-last-child(-n + 2) {
      margin-bottom: 44px;
      .sticky-header {
        padding-bottom: 0;
        position: static;
      }
    }
    &:last-child {
      margin-bottom: 15px;
    }
  }

  .details-collapse {
    margin-bottom: 0;
  }

  .sticky-header {
    width: 100%;
    position: sticky;
    top: 106px;
    padding: 1px 0 15px;
    background: #0b0c23;
    z-index: 55;

    @media (max-width: 374px) {
      top: 87px;
    }
  }
}
