.details_onsale {
  .products__item-wrapper {
    .item-holder {
      width: 100%;
      padding-bottom: 100%;
    }

    .products__item {
      padding: 30px;
      border-color: $secondary_bg;
      background-color: $secondary_bg-sec;
    }

    .products__item-inner {
      display: flex;
      flex-direction: column;
    }

    .products__item-header {
      padding: 0;
    }

    .poducts__item-title {
      margin-bottom: 7px;
    }
  }

  .details__order {
    margin-top: 15px;

    .details__order-input {
      flex-grow: 1;
    }

    .input-holder {
      position: relative;
      background-color: $secondary_bg-sec;

      .input-wrapper {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border: 1px solid $secondary_bg;
        @media (max-width: 768px) {
          min-width: 120px;
        }
        @media (max-width: 424px) {
          min-width: 93px;
        }
        @media (max-width: 374px) {
          min-width: 82px;
        }
      }

      input {
        text-align: center;
      }
    }
  }

  .details__order-share-holder {
    @include block_centered_content(55px, 55px, $btn-main_bg);
    margin-left: 15px;

    @media (max-width: 424px) {
      min-width: 50px;
      height: 50px;
    }

    @media (max-width: 374px) {
      min-width: 40px;
      height: 40px;
    }

    .share-icon {
      margin-top: 3px;
      margin-left: 3px;
      @media (max-width: 374px) {
        width: 10px;
        height: 10px;
        margin-top: -4px;
      }
      svg {
        width: 15px;
        height: 15px;

        @media (max-width: 424px) {
          width: 13px;
          height: 13px;
        }

        @media (max-width: 374px) {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .button__main {
    @media (max-width: 768px) {
      min-width: 189px;
    }
    @media (max-width: 424px) {
      min-width: 172px;
    }

    @media (max-width: 374px) {
      min-width: 138px;
    }
  }
}
