.electicity-switcher {
  margin-right: 30px;
  @include open-sans_font($white_font, 12px);
  font-weight: 300;

  .green-text {
    color: #029e4e;
  }
}

.electicity-switcher__active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70px;
  margin-right: 15px;

  .arrow-left {
    transform: rotate(90deg);

    svg {
      cursor: pointer;
    }
  }

  .arrow-right {
    transform: rotate(-90deg);

    svg {
      cursor: pointer;
    }
  }
}

.view-switcher {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.view-switcher__active {
  display: flex;
  align-items: center;
}

.view-switcher__active-title {
  @include exo_font($grey_font, 10px, normal);
}

.view-switcher__active-btn {
  margin-left: 6px;
  @include open-sans_font($white_font, 10px);
  cursor: pointer;
}

.view-switcher__icon {
  margin-left: 15px;
}
