.arrow-holder {
  position: relative;
  display: flex;
  width: 30px;
  height: 100%;
  align-items: center;

  cursor: pointer;
}

.arrow-holder__right {
  justify-content: flex-end;
  right: -13px;
}

.arrow-holder__left {
  justify-content: flex-start;
  left: -13px;
}

@mixin arrow($fill_color) {
  position: relative;

  svg {
    width: 10px;
    height: 6px;
  }

  path {
    fill: $fill_color;
  }
}

.arrow_left {
  left: 11px;
  transform: rotate(90deg);
}

.arrow_right {
  right: 11px;
  transform: rotate(-90deg);
}

.dots svg {
  width: 2px;
  height: 10px;
}

.toggle-arrow svg {
  width: 10px;
  height: 6px;

  @media (max-width: 768px) {
    width: 12px;
    height: 7px;
  }

  @media (max-width: 374px) {
    width: 10px;
    height: 6px;
  }
}

.toggle-arrow_right svg {
  transform: rotate(-90deg);
}

.toggle-arrow_left svg {
  transform: rotate(90deg);
}

.link-to {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.stop-scroll-y {
  position: absolute;
  width: 100%;
  height: 100%;
}

@mixin block_centered_content($width, $height, $bg) {
  position: relative;
  display: flex;
  min-width: $width;
  height: $height;
  align-items: center;
  justify-content: center;
  background-color: $bg;
}

@mixin drop_menu($width, $right, $diamond_right) {
  position: absolute;
  display: none;
  top: 0;
  right: $right;
  width: $width;
  padding-top: 40px;
  text-transform: none;
  @include open-sans_font($grey_font, 12px);
  z-index: 21;

  button {
    display: block;
  }

  .drop-menu__inner {
    position: relative;
    border-top: 1px solid $grey_font;
    background-color: $popup_bg;

    .diamond-part {
      @include diamond_part($diamond_right);
    }
  }

  .drop-menu__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    align-items: center;
    border-bottom: 1px solid rgba(196, 196, 196, 0.2);

    h4 {
      @include open-sans_font($white_font, 16px);
    }
  }

  .drop-menu__link {
    &:hover {
      color: $white_font;
    }
  }

  .drop-menu__box {
    padding: 15px;
  }

  .drop-menu__footer {
    padding: 20px 15px;
    border-top: 1px solid rgba(196, 196, 196, 0.2);
  }

  .drop-menu__btn-wrapper {
    padding: 20px 0;
  }

  // NOTIFY
  .drop-menu__notify-item-icon {
    margin-right: 10px;
  }
}
