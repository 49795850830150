@import "./coin-about";
@import "./coin-info";

.coins__control {
  display: grid;
  grid-template-columns: 145px 145px;
  grid-gap: 30px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 15px;
  }

  @media (max-width: 374px) {
    grid-template-columns: 1fr;
  }

  .toggle-block {
    height: 60px;

    @media (max-width: 768px) {
      height: 80px;
    }

    @media (max-width: 424px) {
      height: 75px;
    }

    @media (max-width: 374px) {
      height: 65px;
    }
  }

  .icon-holder {
    width: 28px;

    @media (max-width: 374px) {
      width: 26px;
    }

    img {
      width: 14px;

      @media (max-width: 768px) {
        width: 15px;
      }

      @media (max-width: 424px) {
        line-height: 13px;
        width: 13px;
      }

      @media (max-width: 374px) {
        line-height: 11px;
        width: 11px;
      }
    }
  }

  .text-block {
    span {
      display: block;
    }

    .text-block__title {
      @include standart_font($white_font, 400);
    }

    .text-block__subtitle {
      @include standart_font($grey_font, 300);
      line-height: 20px;

      @media (max-width: 768px) {
        line-height: 25px;
      }

      @media (max-width: 424px) {
        line-height: 24px;
      }

      @media (max-width: 374px) {
        line-height: 21px;
      }
    }
  }
}

.coin-info-widescreen {
  display: none;

  @media (min-width: 1920px) {
    display: block;
  }
}
