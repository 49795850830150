.cart-checkout {
  font-family: Open Sans;
  color: $white_font;
  position: absolute;
  top: 70px;
  right: 30px;
  width: 320px;
  border-top: 1px solid $white_font;
  border-left: 1px solid $secondary_bg;
  border-right: 1px solid $secondary_bg;
  border-bottom: 1px solid $secondary_bg;
  background-color: $main_bg;
  z-index: 405;

  .cart-checkout__inner {
    overflow-x: hidden;
  }

  .cart-checkout__header {
    display: flex;
    justify-content: space-between;
    margin: 28px 30px 28px;

    h4 {
      @include open-sans_font($white_font, 16px);
    }

    .close-cross {
      cursor: pointer;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .cart-checkout__body {
    overflow-y: scroll;
  }

  .cart__content {
    max-height: calc(100vh - 419px);
    margin: 0 16px 0 15px;
    .item-list {
      position: relative;
      padding: 28px 12px 0 15px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.02);

      .quantity {
        padding-top: 29px;
        margin-bottom: 7px;
        border-top: 1px solid rgba(196, 196, 196, 0.2);
        text-align: center;
        font-size: 12px;
        span {
          color: $green;
        }
      }

      .invoce {
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 27px;
      }
    }
  }

  .diamond-part {
    @include diamond_part(30px);
  }
}

.cart-checkout__footer {
  padding-top: 56px;
  p {
    line-height: 19px;
    margin: 0 38px 56px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    span {
      color: $green;
    }
  }
  .buttons-container {
    display: flex;
    margin-bottom: 15px;
    button {
      width: 137px;
      height: 40px;
    }
    button:first-child {
      margin-left: 15px;
      margin-right: 16px;
    }
  }
}
