.user-menu__account-hover {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 30px;
  @include open-sans_font($grey_font, $desctop_size_font);
  text-transform: none;

  .user-menu__account-hover-content {
    position: relative;
    box-sizing: border-box;
    width: 150px;
    padding: 0 15px;
    background-color: $popup_bg;
    opacity: 0.98;

    nav {
      padding: 15px 0;

      a {
        position: relative;
        display: block;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        &:hover {
          color: $white_font;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -15px;
            width: 3px;
            height: 100%;
            background-color: $white_font;
          }
        }
      }
    }
  }
}

.user-menu__separate {
  margin: 0 15px;
}

.user-menu__my-account {
  margin: 0 8px;
}

// USER MENU NOTIFY
.user-menu__notify {
  .drop-menu {
    @include drop_menu(275px, calc(100% - 275px), 95px);

    .drop-menu__box {
      padding-bottom: 0;
    }
  }
}

// USER MENU BALACE
.user-menu__balance {

  .icon-balance {
    @media (min-width: 1920px) {
      display: none;
    }
  }

  .user-menu__balance-currency {
    margin-left: 10px;
  }

  .drop-menu {
    @include drop_menu(411px, 0, 260px);
  }

  .list {
    display: flex;
    flex-flow: row wrap;
  }

  .list__item {
    position: relative;
    width: 117px;
    height: 98px;
    box-sizing: border-box;
    border: 1px solid transparent;
    background-color: rgba(11, 12, 35, 0.3);
    cursor: pointer;
  }

  .list-item_active {
    border: 1px solid $secondary_bg;
  }

  .list__item_margin-r-b {
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .list__item_margin-r {
    margin-right: 15px;
  }

  .list__item_margin-b {
    margin-bottom: 15px;
  }

  .list__item-inner {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}
