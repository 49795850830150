.title-block {
  position: relative;
  width: 100%;
  margin-top: 15px;
  background-color: $secondary_bg-sec;

  @media (min-width: 769px) {
    height: 30px;
  }

  @media (max-width: 768px) {
    height: 55px;
  }

  @media (max-width: 424px) {
    height: 50px;
  }

  @media (max-width: 374px) {
    height: 40px;
  }
}

.title-block__inner {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  padding: 0 14px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $secondary_bg;
}

.sticky-title-block {
  position: sticky;
  top: 103px;
  padding: 6px 0 15px;
  background: #0b0c23;
  z-index: 55;

  @media (max-width: 374px) {
    top: 87px;
  }

  .title-block {
    margin-top: 0;
  }
}
