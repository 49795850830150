.products__item-wrapper {
  position: relative;
}

.products__item {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 30px 0;
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: $secondary_bg;
  // transition: background 0.3s ease-in;

  @media (min-width: 769px) {
    position: static;
    width: 213px;
    height: 213px;
    padding: 15px 0;
  }
}

.products__item-desctop_active {
  background-color: rgba(11, 12, 35, 0.8);
  border: 1px solid $secondary_bg;
}

.products__item-inner {
  position: relative;
  height: 100%;
  overflow: hidden;

  //  ORDER block
  .order {
    position: absolute;
    bottom: -90px;
    box-sizing: border-box;
    width: 100%;
    padding: 0 15px;
    animation: 0.3s ease-in;
  }

  .item-price {
    @include open-sans_font($white_font, 16px);
    font-weight: 700;
  }

  .item-price {
    @media (max-width: 768px) {
      font-size: 24px;
    }

    @media (max-width: 424px) {
      font-size: 20px;
    }

    @media (max-width: 374px) {
      font-size: 16px;
    }
  }

  .item-psu {
    font-size: 10px;
    text-transform: uppercase;
    color: $green;
  }

  .order_hover-on {
    bottom: 0;
    animation-name: order-hover-on;
  }

  .order_hover-off {
    animation-name: order-hover-off;
  }

  .select-wrapper {
    height: 30px;
    margin: 15px 0;
  }
}

.details-btn {
  background: rgba(255, 255, 255, 0.1);
  &:hover {
    background-color: $btn-main_bg-active;
  }
}

.products__item-header {
  padding: 0;

  @media (min-width: 769px) {
    padding: 0 15px;
  }
}

.products__item-header-text {
  z-index: 11;
}

.poducts__item-title {
  margin-bottom: 6px;
  line-height: 0.8;
  color: $grey_font;
}

.products__item-hash {
  @include open-sans_font($white_font, 12px);
}

.products__item-star svg {
  @media (min-width: 769px) {
    width: 14px;
    height: 14px;
  }

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 374px) {
    width: 18px;
    height: 18px;
  }
}

// IMG block

.products__item-img {
  flex-grow: 1;
  width: 80%;
  height: auto;
  margin: 16.3% auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: 769px) {
    width: 70%;
    height: 110px;
    margin: 12px auto;
    background-blend-mode: multiply;
    // animation: 0.3s ease-in;
  }
}

.item-img_hover-on {
  filter: blur(20px);
  background-color: $btn-dark_bg;
  animation-name: image-hover-on;
}

.item-img_hover-off {
  animation-name: image-hover-off;
}

// @keyframes image-hover-on {
//   from {
//     filter: none;
//     background-color: rgba(0, 0, 0, 0);
//   }
//   to {
//     filter: blur(20px);
//     background-color: $btn-dark_bg;
//   }
// }

// @keyframes image-hover-off {
//   from {
//     filter: blur(20px);
//     background-color: $btn-dark_bg;
//   }
//   to {
//     filter: none;
//     background-color: rgba(0, 0, 0, 0);
//   }
// }

// @keyframes order-hover-on {
//   from {
//     bottom: -95px;
//   }
//   to {
//     bottom: 0;
//   }
// }

// @keyframes order-hover-off {
//   from {
//     bottom: 0;
//   }
//   to {
//     bottom: -95px;
//   }
// }
