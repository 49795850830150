.drop-list {
  position: relative;
  width: 100%;
  margin-top: 15px;
  background-color: $secondary_bg-sec;

  .drop-list__inner {
    box-sizing: border-box;
    height: 100%;
    border: 1px solid $secondary_bg;
    color: $grey_font;
  }
  
  .active {
    color: $white_font;
    background-color: #26268e;
  }

  .drop-list__item {
    padding-left: 13px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 30px;

    &:hover {
      color: $white_font;

      &::before {
        background-color: $white_font;
      }
    }
  }
}
