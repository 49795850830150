.loader-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    font-family: "Open Sans", sans-serif;
    color: $white_font;

    background-color: $main_bg;
    @media (max-width: 768px) {
        padding: 0 30px 0 30px;
    }

    .loading-container {
        position: relative;

        width: 699px;
        margin: 88px 0 87px;
        @media (max-width: 768px) {
            width: 100%;
            margin: 58px 0 58px;
        }
        .loading-already {
            position: relative;

            width: 0;
            height: 5px;

            background-color: $white_font;

            transition: width 0.75s cubic-bezier(0.6, 0.4, 0.4, 0.6);

            @media (max-width: 768px) {
                height: 5px;
            }

            .count-up {
                display: none;
                position: absolute;
                top: 20px;
                right: 0;

                font-size: 10px;

                @media (max-width: 768px) {
                    font-size: 12px;
                }
                @media (max-width: 424px) {
                    font-size: 11px;
                }
                @media (max-width: 374px) {
                    font-size: 10px;
                }
            }
            .mask {
                position: absolute;
                top: 1px;
                right: 0;
                z-index: 999;

                width: 15px;
                height: 4px;

                background-color: $main_bg;
            }
        }

        .loading-left {
            position: absolute;
            top: 2px;

            width: 100%;
            height: 1px;

            background-color: $white_font;
            opacity: 0.2;
        }
    }
    p {
        font-size: 12px;

        @media (max-width: 768px) {
            font-size: 16px;
        }
        @media (max-width: 424px) {
            font-size: 15px;
        }
        @media (max-width: 374px) {
            font-size: 12px;
        }
    }
}
