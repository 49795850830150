.select-modal {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(11, 12, 35, 0.98);
    .select-wrapper {
        position: relative;
        margin: 0 90px 0;
        width: 100%;

        .select {
            overflow: visible;
            .modal-hide {
                display: none;
            }
        }
    }
}
