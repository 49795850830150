.scroll-layer {
  position: absolute;
  top: 50px;
  right: 0;
  width: 6px;
  height: calc(100% - 50px);
  transform: translateX(50%);
  z-index: 10;
}

.scroll-container {
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll-component {
  position: absolute;
  top: 0;
  right: 50%;
  height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}

.scroll-thumb {
  position: absolute;
  top: 0;
  right: -2px;
  width: 5px;
  height: 90px;
  background: $white_font;
  box-shadow: 0px 15px 0px #0b0c23;
  cursor: pointer;
}
