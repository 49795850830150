.filters {
  padding-right: 32px;
}

.filters__body {
  width: 212px;
  overflow: hidden;
}

.filter-block {
  padding-bottom: 15px;

  @media (min-width: 769px) {
    width: 211px;
    padding: 0;
  }

  .filter {
    padding-bottom: 15px;
  }

  .by-price,
  .by-text {
    input {
      width: 100%;
    }
  }

  .filter__select {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 13px;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: $secondary_bg;

    &:hover {
      background-color: $secondary_bg-sec;
    }

    .select-wrapper {
      display: flex;
      align-items: center;

      .filter-tag {
        position: relative;
        margin-right: 10px;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        color: $white_font;
        background-color: $btn-main_bg;
        overflow: hidden;
        cursor: pointer;

        @media (min-width: 769px) {
          width: 45px;
          height: 18px;
          margin-right: 5px;
          font-size: 10px;
        }

        @media (max-width: 768px) {
          width: 80px;
          height: 35px;
          font-size: 12px;
        }

        @media (max-width: 424px) {
          width: 60px;
          height: 30px;
          font-size: 11px;
        }

        @media (max-width: 374px) {
          width: 50px;
          height: 20px;
          font-size: 10px;
        }

        .filter-tag__content {
          height: 100%;
        }

        .filter-tag__content,
        .filter-tag__close {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .filter-tag__close {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 100%;
          font-weight: 400;
          font-size: 18px;
          background-color: $btn-main_bg;
        }
      }

      .filter-tag__delete {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 768px) {
          width: 35px;
        }

        @media (max-width: 424px) {
          width: 30px;
        }

        @media (max-width: 374px) {
          width: 20px;
        }

        svg {
          @media (max-width: 768px) {
            width: 10px;
          }

          @media (max-width: 424px) {
            width: 8.57px;
          }

          @media (max-width: 374px) {
            width: 5.71px;
          }
        }
      }
    }

    .arrow {
      position: absolute;
      display: flex;
      right: 10px;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @media (min-width: 769px) {
        right: 6px;
      }
    }
  }

  .filter__select_expand {
    background-color: $secondary_bg-sec;
  }

  .filter__select_active {
    border: 1px solid rgba(255, 255, 255, 0.1);

    input::placeholder {
      color: $white_font;
    }
  }

  .filter__items-container {
    display: grid;
    grid-gap: 15px;
    margin-top: 15px;

    .filter__item {
      position: relative;
      cursor: pointer;
    }

    .filter__item_not-found {
      position: relative;
      cursor: default;
    }

    .filter__item-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
      border: 1px solid rgba(0, 0, 0, 0);
      background-color: $secondary_bg;
      color: $grey_font;

      @media (min-width: 769px) {
        font-size: 10px;
      }

      @media (max-width: 768px) {
        font-size: 12px;
      }

      @media (max-width: 424px) {
        font-size: 11px;
      }

      @media (max-width: 374px) {
        font-size: 10px;
      }

      .filter__item-img {
        @media (min-width: 769px) {
          width: 30px;
          margin-bottom: 12px;
        }

        @media (max-width: 768px) {
          width: 37px;
          margin-bottom: 30px;
        }

        @media (max-width: 424px) {
          width: 32px;
          margin-bottom: 23px;
        }

        @media (max-width: 374px) {
          width: 25.6px;
          margin-bottom: 18px;
        }
      }
    }
  }

  .filter__items-container_txt {
    @media (min-width: 769px) {
      grid-auto-rows: 30px;
    }

    @media (max-width: 768px) {
      grid-auto-rows: 55px;
    }

    @media (max-width: 424px) {
      grid-auto-rows: 50px;
    }

    @media (max-width: 374px) {
      grid-auto-rows: 40px;
    }
  }

  .filter__items-container_img {
    @media (min-width: 769px) {
      grid-auto-rows: 80px;
    }

    @media (max-width: 768px) {
      grid-auto-rows: 120px;
    }

    @media (max-width: 424px) {
      grid-auto-rows: 101px;
    }

    @media (max-width: 374px) {
      grid-auto-rows: 82px;
    }
  }
}
