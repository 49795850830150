.details__left {
  .details__item-price-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }

  .details__order {
    margin-top: 15px;

    .counter-box {
      position: relative;
      background-color: $secondary_bg-sec;

      .counter-box__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        height: 100%;
        padding: 0 13px;
        border: 1px solid $secondary_bg;

        .product-quantity {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          cursor: default;
        }

        .arrow {
          @include arrow($grey_font);
        }
      }
    }
  }

  .socials {
    margin-top: 60px;

    .socials-inner {
      display: flex;
      margin-top: 28px;
    }
  }
}
