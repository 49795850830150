.footer-standart {
  margin: 120px 30px 30px;
  align-items: flex-end;
  @include open-sans_font($grey_font, $desctop_size_font);
}

.footer-mini {
  margin: 25px 30px 30px;
  align-items: flex-end;
  @include open-sans_font($grey_font, $desctop_size_font);

  .social {
    display: none;
  }
}

.footer-inner {
  width: 100%;

  .feedback {
    cursor: default;
  }

  .feedback__rate {
    margin-right: 12px;
    @include exo_font($white_font, 30px, normal);
  }

  .feedback__message {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 300;

    .feedback__message-rating {
      align-items: baseline;

      svg {
        width: 9.33px;
        height: 8.94px;
        margin-right: 3px;
      }
    }
  }

  .feedback__author {
    margin-left: 5px;
    font-size: 10px;
  }

  .social {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .awards__point {
    position: relative;
    padding-left: 15px;

    .awards__point-layer {
      position: absolute;
      top: -50px;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 50px;
      z-index: 41;
    }
  }

  .achievement-icon {
    svg {
      width: 100%;
      height: 75px;
    }
  }
}
