.sidebar__content {
  position: relative;
  height: 100%;
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.02);

  @media (min-width: 425px) {
    width: calc(100% - 89px);
  }

  @media (max-width: 424px) {
    width: calc(100% - 83px);
  }

  @media (max-width: 374px) {
    width: calc(100% - 73px);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    background-color: $white_font;
  }

  .awards__item {
    position: relative;
    margin-bottom: 15px;
    background-color: $secondary_bg-sec;

    .awards__item-inner {
      box-sizing: border-box;
      width: 100%;
      padding: 45px 12.5%;
      text-align: center;
      border: 1px solid $secondary_bg;

      svg {
        width: 140px;
        height: auto;

        @media (max-width: 424px) {
          width: 130px;
        }

        @media (max-width: 374px) {
          width: 120px;
        }
      }

      h4 {
        margin: 33px 0 14px;
        line-height: 0.75;
      }

      h4,
      p {
        line-height: 20px;

        @media (max-width: 424px) {
          line-height: 19px;
        }

        @media (max-width: 374px) {
          line-height: 16px;
        }
      }

      .p-mob {
        color: $white_font;
      }
    }
  }

  .sellers__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .img-wrapper img {
      display: block;
      width: 40px;
      border-radius: 50%;

      @media (max-width: 374px) {
        width: 35px;
      }
    }

    .sellers__item-text {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 15px;
    }

    .sellers__item-rating {
      margin-left: 5px;

      svg {
        width: 12px;
        height: auto;

        @media (max-width: 424px) {
          width: 11px;
        }

        @media (max-width: 374px) {
          width: 10px;
        }
      }
    }
  }

  .notification-arrow svg {
    @media (max-width: 374px) {
      width: 8px;
      height: 8px;
    }
  }

  .notification-delete svg {
    @media (min-width: 425px) {
      width: 17px;
      height: 21px;
    }

    @media (max-width: 424px) {
      width: 15px;
      height: 19px;
    }

    @media (max-width: 374px) {
      width: 13px;
      height: 16px;
    }
  }
}

.sidebar__content-inner,
.sidebar__content-inner_mod {
  box-sizing: border-box;
  height: 100%;
  padding: 0 15px;
  border-top: 1px solid $secondary_bg;
  border-left: 1px solid $secondary_bg;
  overflow-y: scroll;

  h2 {
    @media (min-width: 425px) {
      margin: 59px 0 57px 13px;
    }

    @media (max-width: 424px) {
      margin: 60px 0 57px 13px;
    }

    @media (max-width: 374px) {
      margin: 44px 0 43px 13px;
    }
  }
}

.sidebar__content-inner_mod {
  @media (min-width: 425px) {
    height: calc(100% - 85px);
  }

  @media (max-width: 424px) {
    height: calc(100% - 80px);
  }

  @media (max-width: 374px) {
    height: calc(100% - 70px);
  }
}

.sidebar__buttons-holder {
  padding: 15px;
  border-left: 1px solid $secondary_bg;
}
