.auth-content {
  margin: 0 15px 45px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 374px) {
    margin-bottom: 30px;
  }

  @media (min-width: 769px) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -50%);
  }

  .auth-content__social-wrapper {
    @media (min-width: 769px) {
      margin-top: 38px;
    }
  }

  .auth__close-btn {
    position: fixed;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1111;
    cursor: pointer;

    @media (min-width: 769px) {
      display: none;
    }
  }

  .auth-content__social-link {
    @media (min-width: 769px) {
      @include block_centered_content(40px, 40px, $secondary_bg);
    }

    @media (max-width: 768px) {
      @include block_centered_content(55px, 55px, $secondary_bg);
    }

    @media (max-width: 424px) {
      @include block_centered_content(50px, 50px, $secondary_bg);
    }

    @media (max-width: 374px) {
      @include block_centered_content(40px, 40px, $secondary_bg);
    }

    &:not(:last-child) {
      margin-right: 30px;
    }

    &:hover {
      background-color: $btn-main_bg;
    }

    svg {
      @media (max-width: 424px) {
        height: 14.55px;
      }

      @media (max-width: 374px) {
        height: 11.64px;
      }
    }
  }

  .auth-content__or {
    position: relative;
    margin: 53px 0;
    font-weight: 300;

    @media (min-width: 769px) {
      margin: 38px 0;
    }

    @media (min-width: 425px) {
      @include open-sans_font($white_font, 16px);
    }

    @media (max-width: 424px) {
      @include open-sans_font($white_font, 15px);
    }

    @media (max-width: 374px) {
      @include open-sans_font($white_font, 12px);
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0%;
      width: calc(50% - 25px);
      height: 1px;
      background-color: #fff;
      opacity: 0.2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0%;
      width: calc(50% - 25px);
      height: 1px;
      background-color: #fff;
      opacity: 0.2;
    }
  }

  .auth-content__form {
    @media (min-width: 769px) {
      margin-top: 38px;
    }

    .auth-content__input-wrapper {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      input {
        position: relative;
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 0 14px;
        border: 1px solid transparent;
        border-radius: 0;
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        background-color: $secondary_bg;
        color: $grey_font;
        outline: 0;
        z-index: 11;

        @media (min-width: 769px) {
          width: 320px;
          height: 40px;
          font-size: $desctop_size_font;
        }

        @media (max-width: 768px) {
          height: 55px;
          font-size: 16px;
        }

        @media (max-width: 424px) {
          height: 50px;
          font-size: 15px;
        }

        @media (max-width: 374px) {
          height: 40px;
          font-size: 12px;
        }

        &::placeholder {
          color: $grey_font;
        }

        &:hover {
          background-color: $secondary_bg-sec;
          color: $white_font;
        }
      }
      .password,
      .password-confirm {
        padding-top: 4px;
      }
      .password:placeholder-shown,
      .password-confirm:placeholder-shown {
        padding-top: 0;
      }
    }

    .field-error,
    .email-error,
    .password-error,
    .password-confirm-error {
      display: none;
      position: absolute;
      bottom: -3px;
      left: 10px;
      padding: 0 5px;
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
      text-transform: initial;
      color: #ff4757;
      background-color: $main_bg;
      z-index: 11;

      @media (min-width: 769px) {
        font-size: 9px;
      }

      @media (max-width: 768px) {
        bottom: -4px;
        font-size: 12px;
      }

      @media (max-width: 424px) {
        font-size: 11px;
      }

      @media (max-width: 374px) {
        font-size: 10px;
      }
    }
  }

  .auth-content__terms {
    margin-top: 30px;
    text-align: right;
    text-transform: initial;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: $grey_font;

    @media (min-width: 769px) {
      margin-top: 15px;
    }

    @media (min-width: 425px) {
      font-size: 12px;
    }

    @media (max-width: 424px) {
      font-size: 11px;
    }

    @media (max-width: 374px) {
      font-size: 10px;
    }

    a {
      @media (max-width: 768px) {
        text-decoration: underline;
      }
    }

    .terms-link {
      @include open-sans_font($white_font, 12px);
      text-transform: initial;
      font-weight: 300;

      &:hover {
        text-decoration: underline;
      }

      @media (max-width: 424px) {
        font-size: 11px;
      }

      @media (max-width: 374px) {
        font-size: 10px;
      }
    }
  }

  .auth-content__btn {
    margin: 57px auto 0;

    .button__dark {
      background-color: $secondary_bg;

      &:hover {
        background-color: $btn-main_bg;
      }
    }

    @media (min-width: 769px) {
      width: 130px;
      margin: 37px auto 0;

      .button {
        height: 40px;
        font-size: 14px;
      }
    }

    @media (max-width: 768px) {
      width: 189px;
    }

    @media (max-width: 424px) {
      width: 172px;
    }

    @media (max-width: 374px) {
      width: 138px;
    }
  }

  .auth-content__btn-switcher {
    display: block;
    margin-top: 60px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: $white_font;
    text-transform: none;
    text-decoration: underline;

    @media (min-width: 769px) {
      display: none;
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 424px) {
      font-size: 15px;
    }

    @media (max-width: 374px) {
      font-size: 12px;
    }
  }
}
