.listings {
  display: none;
  padding-left: 31px;
  padding-right: 1px;

  @media (min-width: 1440px) {
    display: block;
  }
}

// when the "list view products" is enable
.listings-d-var {
  display: none;

  @media (min-width: 1920px) {
    display: block;
    padding-left: 31px;
  }
}

.listings__body {
  width: 213px;
  overflow: hidden;

  @media (min-width: 2560px) {
    width: 729px;
  }

  .listings__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 213px);
    grid-gap: 30px;
    width: 213px;

    @media (min-width: 2560px) {
      width: 729px;
    }
  }
}
