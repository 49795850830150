.news {
  display: none;

  @media (min-width: 1440px) {
    display: block;
    margin-left: 30px;
  }

  @media (min-width: 2560px) {
    margin: 0;
  }
}

.news_pdp {
  display: none;

  @media (min-width: 1920px) {
    display: block;
  }
}

.news__body {
  width: 209px;
  overflow: hidden;
  
  .banner-wrapper {
    position: relative;
    width: 209px;
    height: 699px;
    background-color: $secondary_bg;

    &:hover {
      background-color: $secondary_bg-sec;
    }

    .banner {
      box-sizing: border-box;
      height: 100%;
      border: 1px solid rgba(255, 255, 255, 0);
      text-align: center;

      &:hover {
        border-color: $secondary_bg;
      }

      h2 {
        line-height: 30px;
        letter-spacing: -0.5px;
      }

      li {
        font-weight: 400;
        line-height: 30px;
        color: $white_font;
      }

      .banner__logo-buffer {
        height: 6.45%;
      }

      .banner__title-buffer {
        height: 8.5%;
      }

      .banner__benefits-buffer {
        height: 4%;
      }

      .banner__illustration-buffer {
        height: 7%;
      }
    }
  }
}
