.header {
  position: fixed;
  width: 100%;
  padding-top: 30px;
  @include exo_font($white_font, 14px, bold);
  text-transform: uppercase;
  background-color: #0b0c23;
  z-index: 405;

  @media (min-width: 769px) {
    position: static;
    z-index: initial;

    .header__btn {
      @include exo_font($white_font, 14px, bold);
      text-transform: uppercase;
      color: #fff;
    }
  }

  .controls-btn {
    display: flex;
    cursor: pointer;
  }

  .controls-btn_open svg {
    @media (min-width: 425px) {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 424px) {
      width: 18px;
      height: 18px;
    }

    @media (max-width: 374px) {
      width: 16px;
      height: 16px;
    }
  }

  .controls-btn_close svg {
    @media (min-width: 425px) {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 424px) {
      width: 18px;
      height: 18px;
    }

    @media (max-width: 374px) {
      width: 16px;
      height: 16px;
    }
  }

  .logo-wrapper {
    .logo {
      display: flex;
      cursor: pointer;

      svg {
        @media (min-width: 769px) {
          height: auto;
        }

        @media (max-width: 768px) {
          height: 18.16px;
        }

        @media (max-width: 424px) {
          height: 16.5px;
        }

        @media (max-width: 374px) {
          height: 14.85px;
        }
      }
    }
  }
}

.header__navbar {
  display: flex;
  justify-content: space-between;

  @media (min-width: 769px) {
    padding: 0 30px;
  }

  @media (max-width: 768px) {
    padding: 0 30px 56px;
  }

  @media (max-width: 374px) {
    padding: 0 30px 42px;
  }

  a {
    display: flex;
  }
}

.header__navbar_terms {
  @media (max-width: 768px) {
    .controls-btn,
    .cart-btn {
      visibility: hidden;
    }
  }
}

.main-menu {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);

  @media (min-width: 1920px) {
    transform: translate(-50%, 5px);
  }

  .burger-btn {
    @media (min-width: 1920px) {
      display: none;
    }

    a {
      display: flex;

      svg {
        width: 23px;
        height: 23px;
      }
    }
  }
}

.account-menu {
  position: relative;
  width: 20px;

  @media (min-width: 769px) {
    width: initial;
  }
}

.account-menu__btn-group {
  button {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:first-child {
      margin-right: 30px;
    }
  }

  .pencil-icon,
  .login-icon {
    margin-right: 10px;
  }
}
