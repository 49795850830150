.lang-menu {
  position: relative;
  display: none;
  margin-left: 30px;

  @media (min-width: 769px) {
    display: flex;
  }
}

.lang-menu__btn {
  position: relative;
  min-width: 28px;
  text-align: left;
  z-index: 99;

  &:hover {
    color: $text_hover;
  }
}

.lang-menu__modal {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  width: 90px;
  height: 50px;
  padding-top: 40px;

  .lang-menu__modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    border-top: 1px solid $grey_font;
    background: rgba(23, 24, 46, 0.98);
    cursor: pointer;

    .diamond-part {
      @include diamond_part(25%);
    }
  }

  .flag-icon {
    margin-right: 15px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}
