.button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: $white_font;
  cursor: pointer;

  @media (min-width: 769px) {
    height: 30px;
    font-size: 12px;
  }

  @media (max-width: 768px) {
    height: 55px;
    font-size: 16px;
  }

  @media (max-width: 424px) {
    height: 50px;
    font-size: 15px;
  }

  @media (max-width: 374px) {
    height: 40px;
    font-size: 12px;
  }

  .value-holder {
    display: flex;
    align-items: center;
  }
}

.button__main {
  background-color: $btn-main_bg;

  &:hover {
    background-color: $btn-main_bg-active;
  }
}

.button__dark {
  background-color: $btn-dark_bg;

  &:hover {
    background-color: $btn-main_bg;
  }
}

.button_grey {
  background-color: $secondary_bg;
}

.square-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  @include fontN2($white_font, 400);
  background-color: $btn-main_bg;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 55px;
    height: 55px;
  }

  @media (max-width: 424px) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 374px) {
    width: 40px;
    height: 40px;
  }
}

.square-btn_active {
  @media (max-width: 768px) {
    background-color: $btn-main_bg-active;
  }
}
